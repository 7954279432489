import React from "react";
import styled from "styled-components";

const SettingsView = styled.div`
  height: 80vh;
  width: 100%;
`;

const Settings = () => {
  return <SettingsView>Settings</SettingsView>;
};

export default Settings;
