import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";

const SkipButton = styled(Button)`
  outline: none;
  border: none;
  background-color: white;
  color: rgb(209, 115, 77);
`;

const ButtonContainer = styled.div`
  &:hover ${SkipButton} {
    outline: none;
    background-color: white;
    color: rgb(209, 115, 77);
  }
`;

const Skip = withRouter(({ history, className }) => {
  return (
    <ButtonContainer
      onClick={() => {
        history.push("/main/places");
      }}
      className={className}
    >
      <SkipButton className={className}>Skip</SkipButton>
    </ButtonContainer>
  );
});

export default Skip;
