import {
  PLACES_HAS_ERRORED,
  PLACES_IS_LOADING,
  PLACES_FETCH_DATA_SUCCESS,
  FAVORITES_ADD_SUCCESS,
  FAVORITES_REMOVE_SUCCESS,
  FAVORITES_FETCH_SUCCESS,
  FAVORITES_IS_LOADING
} from "../../constants/action-types.js";
import { IMAGES_URL } from "../../../env";

const placesInitialState = {
  placesIsLoading: false,
  favoritesIsLoading: false,
  placesHasErrored: false,
  favoritesLoaded: false,
  placesLoaded: false,
  places: [],
  favorites: []
};

function prependURL(places) {
  return places.map(place => ({
    ...place,
    cover: IMAGES_URL.concat(place.cover),
    coverPreview: IMAGES_URL.concat(place.coverPreview),
    images: place.images.map(image => ({
      thumbnail: IMAGES_URL.concat(image.thumbnail),
      original: IMAGES_URL.concat(image.original)
    }))
  }));
}

export function reducer(state = placesInitialState, action) {
  switch (action.type) {
    case PLACES_HAS_ERRORED:
      return { ...state, placesHasErrored: action.placesHasErrored };
    case PLACES_IS_LOADING:
      return { ...state, placesIsLoading: action.placesIsLoading };
    case PLACES_FETCH_DATA_SUCCESS:
      return {
        ...state,
        places: prependURL(action.places),
        placesLoaded: true
      };
    case FAVORITES_ADD_SUCCESS:
      return {
        ...state,
        favorites: state.favorites.concat(prependURL(action.newFavorites))
      };
    case FAVORITES_IS_LOADING:
      return {
        ...state,
        favoritesIsLoading: action.favoritesIsLoading
      };
    case FAVORITES_REMOVE_SUCCESS:
      let favoritesToRemove = new Set(
        action.deletedFavorites.map(deleted => deleted._id.$oid)
      );
      return {
        ...state,
        favorites: state.favorites.filter(
          favorite => !favoritesToRemove.has(favorite._id.$oid)
        )
      };

    case FAVORITES_FETCH_SUCCESS:
      return {
        ...state,
        favoritesLoaded: true,
        favorites:
          action.favorites.length > 0 ? prependURL(action.favorites) : []
      };
    default:
      return state;
  }
}
