import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { sharedButtonStyles } from "../../../sharedStyles";
import { connect } from "react-redux";
import {
  favoritePlacesAdd,
  favoritePlacesRemove
} from "../../../redux/actions/places";

const activeColor = "red";
const inactiveColor = "white";

const LikeContainer = styled.div`
  z-index: 100;
  ${sharedButtonStyles};
  .fa-heart path {
    stroke: red;
    stroke-width: 10;
  }
`;

const StyledLikeButton = styled(Button)`
  outline: none;
  border: none;
  background-color: white;
`;

const getColor = isFavorite => (isFavorite ? activeColor : inactiveColor);

const mapStateToProps = state => ({
  props: {
    favorites: state.placesState.favorites,
    userId: state.userState.user._id.$oid,
    isLoading: state.placesState.favoritesIsLoading
  }
});
const mapDispatchToProps = {
  favoritePlacesAdd,
  favoritePlacesRemove
};

const placeIsFavorite = (favorites, placeId) =>
  favorites.length > 0 &&
  favorites.find(favorite => favorite._id.$oid === placeId);

const Like = ({
  props,
  className,
  placeId,
  favoritePlacesAdd,
  favoritePlacesRemove
}) => {
  const { favorites, userId, isLoading } = props;
  const isFavoritePlace = placeIsFavorite(favorites, placeId);

  return (
    <LikeContainer className={className}>
      <StyledLikeButton
        onClick={e => {
          e.preventDefault();
          isFavoritePlace
            ? favoritePlacesRemove(userId, [placeId])
            : favoritePlacesAdd(userId, [placeId]);
        }}
        className={className}
        disabled={isLoading}
      >
        <FontAwesomeIcon
          className={className}
          size="lg"
          icon="heart"
          //TODO handle this as part of styled component
          style={{ color: getColor(isFavoritePlace) }}
        />
      </StyledLikeButton>
    </LikeContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Like);
