import React from "react";
import Iframe from "react-iframe";

const Home = () => {
  return (
    <Iframe
      url="https://dogsallowedlaunch.squarespace.com/"
      width="100%"
      height="100%"
    />
  );
};

export default Home;
