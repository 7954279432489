import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Div100vh from "react-div-100vh";
import Header from "../header/Header";

const GDPRLayout = styled(Div100vh)`
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

const GDPRContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
`;

const GDPR = withRouter(() => (
  <GDPRLayout>
    <Header />
    <GDPRContainer>
      <p>
        This Privacy Notice explains how Dogs Allowed handle personal data. We
        aim to the meet requirements of the Data Protection Act 2018, the
        General Data Protection Regulation (GDPR) 2018, the guidelines on the
        Information Commissioner's website as well as our professional
        guidelines and requirements.
      </p>
      <p>
        You will be asked to provide personal information when signing up to use
        or test Dogs Allowed. The purpose of us processing this data is to
        provide optimum information.
      </p>
      <p>​ The categories of data we process are:</p>
      <ul>
        <li> Special category data including name and Facebook account.</li>
        <li>
          Personal data for the purposes of direct mail/email/text/other
          marketing.
        </li>
        <li>
          Special category data including references and details of criminal
          record checks for managing our Dogs Allowed team.
        </li>
        <li>
          Personal data for the purposes of managing our Dogs Allowed team.
        </li>
      </ul>
      <p>
        Personal data is obtained when an individual signs up to Dogs Allowed
        either via Facebook or by registering with an email and the lawful basis
        for processing personal data such as name, address, email or phone
        number is:
      </p>
      <p>
        Processing is necessary for the performance of a contract with the data
        subject or to take steps to enter into a contract
      </p>
      <p>Consent of the data subject</p>
      <p>
        The retention period of personal data is 3 years after it was last
        processed. The retention period for our team is 7 years.
      </p>
      <p>
        We never pass your personal details to a third party except in the
        normal course of business ran via our online providers. To read more
        about how they store and manage your personal data please click on
        Facebook (www.facebook.com).
      </p>
      <p>You have the following personal data rights:</p>
      <ul>
        <li> The right to be informed</li>
        <li>The right of access The right of rectification</li>
        <li> The right to erasure</li>
        <li>The right to restrict processing</li>
        <li> The right to data portability</li>
        <li> The right to object</li>
      </ul>
      <p>
        ​Further details of these rights can be found at the Information
        Commissioner's website.
      </p>
      <p>
        Our data controller is Darina, who is also our Information Governance
        Lead. Please contact Darina for a comment, suggestion or a complaint
        about your data processing at darina@dogsallowed.io. We take complaints
        very seriously.
      </p>
      <p>
        If you are unhappy with our response or if you need any advice you
        should contact the Information Commissioner's Office (ICO). Their
        telephone number is 0303 123 1113. The ICO can investigate your claim
        and take action against anyones who is found to have misused personal
        data. You can also visit their website for information on how to make a
        data protection complaint.
      </p>
    </GDPRContainer>
  </GDPRLayout>
));

export default GDPR;
