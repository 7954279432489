import {
  USER_GET_ERRORED,
  USER_GET_LOADING,
  USER_GET_SUCCESS,
  LOGIN_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  GET_LOGIN_CAPCHA_SUCCESS
} from "../constants/action-types";
import axios from "axios";
import { SERVICES_URL } from "../../env";

export function getUserHasErrored(bool) {
  return {
    type: USER_GET_ERRORED,
    hasErrored: bool
  };
}
export function getUserIsLoading(bool) {
  return {
    type: USER_GET_LOADING,
    isLoading: bool
  };
}
export function getUserSuccess(user) {
  return {
    type: USER_GET_SUCCESS,
    user: user
  };
}
export function loginUserSuccess(user) {
  return {
    type: LOGIN_USER_SUCCESS,
    userId: user._id.$oid
  };
}
export function registerUserSuccess(user) {
  return {
    type: CREATE_USER_SUCCESS,
    userId: user.$oid
  };
}

export function getLoginCapchaSuccess(token) {
  return {
    type: GET_LOGIN_CAPCHA_SUCCESS,
    capchaToken: token
  };
}

export const getCapchaCallback = recaptchaToken => {
  return dispatch => dispatch(getLoginCapchaSuccess(recaptchaToken));
};

export function getUser(fbResponse) {
  return dispatch => {
    dispatch(getUserIsLoading(true));
    axios
      .post(SERVICES_URL.concat("/api/user"), { ...fbResponse })
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        return response.data;
      })
      .then(user => {
        console.log(user);
        dispatch(getUserSuccess(user));
        dispatch(getUserIsLoading(false));
      })
      .catch(() => dispatch(getUserHasErrored(true)));
  };
}

export function registerUser(userCreationRequest) {
  return dispatch => {
    dispatch(getUserIsLoading(true));
    axios
      .post(SERVICES_URL.concat("/api/user/register"), {
        ...userCreationRequest
      })
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        return response.data;
      })
      .then(user => {
        console.log(user);
        dispatch(registerUserSuccess(user));
        dispatch(getUserIsLoading(false));
      })
      .catch(() => dispatch(getUserHasErrored(true)));
  };
}

export function loginUser(loginRequest) {
  return dispatch => {
    dispatch(getUserIsLoading(true));
    axios
      .post(SERVICES_URL.concat("/api/user/login"), {
        ...loginRequest
      })
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        return response.data;
      })
      .then(user => {
        dispatch(loginUserSuccess(user));
        dispatch(getUserIsLoading(false));
      })
      .catch(() => dispatch(getUserHasErrored(true)));
  };
}
