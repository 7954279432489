import React, { Component } from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { placesFetchData } from "../../redux/actions/places";

const Localizator = () => {
  return <div>Localization</div>;
};

export default Localizator;
/*
GeoLocation.propTypes = {
  fetchPlaces: PropTypes.func.isRequired,
  places: PropTypes.array.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    places: state.places,
    hasErrored: state.placesHasErrored,
    isLoading: state.placesIsLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPlaces: () => dispatch(placesFetchData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeoLocation);
*/
