import React from "react";
import styled from "styled-components";
import Description from "./Description";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  /*should be in the higher component*/
  margin-right: 10px;
  height: auto;
  width: auto;
`;
const ImageContainer = styled.div`
  background: #eeeeee;
  height: 95px;
  width: 95px;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: inline-block; /* Instead of display: block; */
  margin: 0 auto;
  vertical-align: middle;
`;

const PlacePreview = withRouter(({ history, place }) => {
  return (
    <Container
      onClick={() => {
        history.push({ pathname: "/main/place", state: place });
      }}
    >
      <Description place={place} />
      <ImageContainer>
        <Image src={place.cover} />
      </ImageContainer>
    </Container>
  );
});

export default PlacePreview;
