import React, { useState } from "react";
import styled from "styled-components";
import { Form, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { DogsAllowedButton } from "../../sharedStyles";
import Header from "../header/Header";
import { ReactComponent as DogLogo } from "../../images/Dakel_only_gradient.svg";
import { ReCaptcha } from "react-recaptcha-v3";
import { connect } from "react-redux";
import { registerUser } from "../../redux/actions/users";
import { Redirect, Link } from "react-router-dom";
import Theme from "../../Theme";
import Div100vh from "react-div-100vh";

const RegisterContainer = styled(Div100vh)`
  display: flex;
  flex-direction: column;
`;

const RegisterContent = styled.div`
  height: auto;
  width: auto;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  flex: 1;
`;

const ButtonLogo = styled(DogLogo)`
  height: 4vh;
  margin: auto;
  float: right;
  path {
    fill: white;
  }
`;

const AvatarPicker = styled(DogLogo)`
  height: 40px;
  path {
    fill: white;
  }
`;
const AvatarContainer = styled.div`
  grid-column-start: 2;
  grid-row-start: 1;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: rgb(238, 238, 238);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
  justify-items: center;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.pinkLabel};
`;

const RegisterButton = styled(Button)`
  ${DogsAllowedButton};
`;

const RegiasterText = styled.div`
  text-align: center;
  margin-top: 2vh;
  color: rgb(194 194 194);
`;

const Label = styled(Form.Check.Label)`
  font-size: 10px;
`;

const LegalLabel = styled(Form.Group)`
  margin-bottom: 0rem;
`;

const RegistrationForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Login = styled.a`
  color: rgb(205 110 70);
`;

const FormContent = styled.div``;
const FormSubmission = styled.div``;

const mapStateToProps = state => ({
  props: {
    userId: state.userState.user !== null ? state.userState.user._id.$oid : null
  }
});
const mapDispatchToProps = {
  registerUser
};

const Register = ({ history, props, registerUser }) => {
  const { userId } = props;
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [validated, setValidated] = useState(false);
  const verifyCallback = recaptchaToken => {
    setRecaptchaToken(recaptchaToken);
  };

  const handleSubmit = async event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const userCreationRequest = {
        captchaToken: recaptchaToken,
        name: event.target.name.value,
        email: event.target.email.value,
        password: event.target.password.value
      };
      await registerUser(userCreationRequest);
    }
    setValidated(true);
  };

  return (
    <Theme>
      <RegisterContainer>
        {userId && <Redirect to="/main/featured" />}
        <ReCaptcha
          sitekey="6Ld7nM8UAAAAAA-vvSyIGjPYdIZAlxq7XpJAj4To"
          action="REGISTER"
          verifyCallback={verifyCallback}
        />
        <Header />
        <HeaderContainer>
          <AvatarContainer>
            <AvatarPicker />
          </AvatarContainer>
        </HeaderContainer>
        <RegisterContent>
          <RegistrationForm
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <FormContent>
              <Form.Group controlId="name">
                <Form.Label className="text-muted">Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter your name"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  We need to know your name
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label className="text-muted">Email address</Form.Label>
                <Form.Control required type="email" placeholder="Enter email" />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Email is used as a username, we need it
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label className="text-muted">Password</Form.Label>
                <Form.Control required type="password" placeholder="Password" />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  We need you password
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="confirmPassword">
                <Form.Label className="text-muted">Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Confirm Password"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Passwords must match
                </Form.Control.Feedback>
              </Form.Group>
              <LegalLabel controlId="acceptPricayAndTerms">
                <Form.Check
                  required
                  type="checkbox"
                  style={{ display: "inline", marginRight: "5px" }}
                />
                <Label>
                  I have read and agreed to{" "}
                  <StyledLink to="/privacy-policy">
                    Terms of Services and Privacy Policy
                  </StyledLink>
                </Label>
                <Form.Control.Feedback type="invalid">
                  Please read and accept Terms of Services and Privacy Note
                </Form.Control.Feedback>
              </LegalLabel>
              <LegalLabel controlId="acceptGDPR">
                <Form.Check
                  required
                  type="checkbox"
                  style={{ display: "inline", marginRight: "5px" }}
                />
                <Label>
                  I have read and agreed to{" "}
                  <StyledLink to="/gdpr">GDPR</StyledLink>
                </Label>
                <Form.Control.Feedback type="invalid">
                  Please read and accept GDPR
                </Form.Control.Feedback>
              </LegalLabel>
            </FormContent>
            <FormSubmission>
              <RegisterButton variant="primary" type="submit">
                Register & Login
                <ButtonLogo />
              </RegisterButton>
              <RegiasterText>
                You already have any accounts?
                <Login
                  href=""
                  onClick={() => {
                    history.push("/login");
                  }}
                >
                  Login
                </Login>
              </RegiasterText>
            </FormSubmission>
          </RegistrationForm>
        </RegisterContent>
      </RegisterContainer>
    </Theme>
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Register)
);
