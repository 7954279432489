export const PLACES_HAS_ERRORED = "PLACES_HAS_ERRORED";
export const PLACES_IS_LOADING = "PLACES_IS_LOADING";
export const PLACES_FETCH_DATA_SUCCESS = "PLACES_FETCH_DATA_SUCCESS";

export const LOCATION_HAS_ERRORED = "LOCATION_HAS_ERRORED";
export const LOCATION_IS_LOADING = "LOCATION_IS_LOADING";
export const LOCATION_FETCH_SUCCESS = "LOCATION_FETCH_SUCCESS";
export const USER_GET_ERRORED = "USER_GET_ERRORED";
export const USER_GET_LOADING = "USER_GET_LOADING";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const FAVORITES_ADD_SUCCESS = "FAVORITES_ADD_SUCCESS";
export const FAVORITES_REMOVE_SUCCESS = "FAVORITES_REMOVE_SUCCESS";
export const FAVORITES_FETCH_SUCCESS = "FAVORITES_FETCH_SUCCESS";
export const FAVORITES_IS_LOADING = "FAVORITES_IS_LOADING";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const GET_LOGIN_CAPCHA_SUCCESS = "GET_LOGIN_CAPCHA_SUCCESS";
