import { css } from "styled-components";

export const sharedButtonStyles = css`
  &:hover .btn,
  .btn-primary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: white;
    text-decoration: none;
    border-color: white;
  }
  .btn-primary {
    background-color: white;
    border-color: white;
  }
  .primary:focus,
  .btn-primary:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0rem #fff;
    outline-color: white;
  }
`;

export const DogsAllowedButton = css`
  width: 100%;
  text-align: left;
  display: block;
  background: rgb(237, 137, 62);
  border-color: white;
  background: linear-gradient(
    90deg,
    rgba(237, 137, 62, 1) 0%,
    rgba(230, 95, 92, 1) 51%,
    rgba(230, 68, 128, 1) 100%
  );
`;

export const MapDecorators = css`
  button.gm-control-active.gm-fullscreen-control {
    display: none;
  }
  div a div img {
    display: none;
  }
  div.gmnoprint {
    display: none;
  }
  .gm-style-cc {
    display: none;
  }
`;
