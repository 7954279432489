import React from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import HeartIcon from "../../../images/svgs/HeartIcon";
import MapIcon from "../../../images/svgs/MapIcon";
import MainGray from "../../../images/dach_main_button_grey.png";
import MainGred from "../../../images/dach_main_button_gred.png";

const FooterButton = styled(Button)`
  outline: none;
  border: none;
  background-color: white;
`;

export const PlacesNav = ({ className, handleClick, isbuttonactive }) => (
  <FooterButton className={className} onClick={handleClick}>
    {isbuttonactive ? (
      <img src={MainGred} width="40px" height="auto" alt={"active"} />
    ) : (
      <img src={MainGray} width="40px" height="auto" alt={"inactive"} />
    )}
  </FooterButton>
);

export const FavoritesNav = ({ className, handleClick, isbuttonactive }) => (
  <FooterButton className={className} onClick={handleClick}>
    <HeartIcon width="30px" isactive={isbuttonactive} />
  </FooterButton>
);

export const LocationNav = ({ className, isbuttonactive, handleClick }) => (
  <Button className={className} onClick={handleClick}>
    <MapIcon width="30px" isactive={isbuttonactive === 1 ? true : false} />
  </Button>
);
/*
export const SettingsNav = ({ className, handleClick, isbuttonactive }) => (
  <FooterButton className={className} onClick={handleClick}>
    <FontAwesomeIcon
      icon="cog"
      font-size="30px"
      style={{ color: getColor(isbuttonactive) }}
    />
  </FooterButton>
);
*/
