import React, {
  useState,
  useRef,
  forwardRef,
  useLayoutEffect,
  useImperativeHandle,
  useEffect
} from "react";
import GoogleMapReact from "google-map-react";
import PlaceMarker from "./PlaceMarker";
import styled from "styled-components";
import { MapDecorators } from "../../../sharedStyles";
import PlacePreview from "./PlacePreview";
import { useHistory } from "react-router-dom";
import Theme from "../../../Theme";

const MapContainer = styled.div`
  height: auto;
  width: 100vw;
  position: relative;
  display: contents;
  ${MapDecorators};
`;
const PlacePreviewLayout = styled(PlacePreview)`
  width: 90vw;
  position: absolute;
  bottom: 4vh;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  left: 5vw;
  z-index: 1;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  z-index: 0;
  background: gray;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Map = ({ places, favoriteToggleHandlers }) => {
  const history = useHistory();

  const [defaultCords, setDefaultCords] = useState({
    lat: 52.2053,
    lng: 0.1218
  });
  const [zoomLevel, setZoomLevel] = useState(15);
  const [isPlaceViewOpen, setIsPlaceViewOpen] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState(undefined);
  const isZoomTresholdReached = zoomLevel => zoomLevel >= 15;
  const closePlacePreviewHandler = () => {
    setIsPlaceViewOpen(false);
    setSelectedPlaceId(undefined);
  };
  const getSelectedPlace = () =>
    places.find(place => place._id.$oid === selectedPlaceId);
  const togglePlaceView = id => {
    setIsPlaceViewOpen(true);
    setSelectedPlaceId(id);
  };

  const selectedPlace =
    selectedPlaceId !== undefined &&
    selectedPlaceId !== null &&
    isZoomTresholdReached(zoomLevel)
      ? getSelectedPlace()
      : null;
  const cords =
    selectedPlaceId !== undefined &&
    selectedPlace !== undefined &&
    isZoomTresholdReached(zoomLevel)
      ? selectedPlace.cords
      : defaultCords;

  const zoom = selectedPlaceId !== undefined ? 15 : zoomLevel;

  return (
    <Theme>
      <MapContainer>
        <GoogleMapReact
          onChange={event => setZoomLevel(event.zoom)}
          bootstrapURLKeys={{ key: "AIzaSyDOk68zyulvX1vsyCUl_8NE7A4_I2HMrpI" }}
          zoom={zoom}
          center={cords}
          style={{
            zIndex: 1,
            opacity: !isZoomTresholdReached(zoomLevel) ? 0.2 : 1
          }}
        >
          {isZoomTresholdReached(zoomLevel) &&
            places.map(place => {
              const {
                _id: { $oid },
                cords,
                name,
                shortDescription
              } = place;
              return (
                <PlaceMarker
                  key={$oid}
                  placeId={$oid}
                  isActive={selectedPlaceId === $oid ? true : false}
                  toggle={togglePlaceView}
                  name={name}
                  shortDescription={shortDescription}
                  lat={cords.lat}
                  lng={cords.lng}
                  zoomLevel={zoomLevel}
                />
              );
            })}
        </GoogleMapReact>

        {!isZoomTresholdReached(zoomLevel) && (
          <Placeholder>Zoom in to discover more places</Placeholder>
        )}

        {selectedPlace && (
          <PlacePreviewLayout
            isZoomLevelTooLow={isZoomTresholdReached(zoomLevel)}
            favoriteToggleHandlers={favoriteToggleHandlers}
            history={history}
            selectedPlace={selectedPlace}
            isOpen={isPlaceViewOpen}
            handlePlacePreview={closePlacePreviewHandler}
          />
        )}
      </MapContainer>
    </Theme>
  );
};

export default Map;
