import React from "react";
import styled from "styled-components";
import Like from "../../helpers/like/Like";
import Reviews from "../../helpers/Reviews";

const StyledPlaceOverview = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto auto auto;
  align-items: baseline;
  align-items: end;
`;

const ReviewsDescription = styled.label`
  font-size: 10px;
  color: rgb(160, 160, 160);
  margin-bottom: 0px;
`;

const Distance = styled.label`
  grid-column-start: 3;
  grid-row-start: 2;
  font-size: 10px;
  justify-self: end;
  padding-right: 1vw;
  color: rgb(204, 204, 204);
  margin-bottom: 0px;
`;

const StyledReviews = styled(Reviews)`
  white-space: nowrap;
`;

const LikeLayout = styled(Like)`
  justify-self: end;
  grid-column-start: 3;
  grid-row-start: 1;
  padding-bottom: 0px;
  margin-bottom: -2px;
`;

const VenueName = styled.h5`
  font-family: Roboto;
  grid-column-start: 1;
  grid-row-start: 1;
  font-size: 12px;
  color: rgb(113, 113, 113);
  margin-bottom: 0px;
`;
const ReviewLayout = styled.div`
  grid-column-start: 1;
  grid-row-start: 2;
  align-items: baseline;
  justify-content: start;
  display: flex;
`;

const ShortDescription = styled.span`
  font-size: 10px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
`;

const PlaceOverview = ({
  review,
  distance,
  name,
  className,
  shortDescription,
  placeId
}) => (
  <StyledPlaceOverview className={className}>
    <VenueName>{name}</VenueName>
    <LikeLayout placeId={placeId} />
    <ReviewLayout>
      <StyledReviews review={review} />
      <ReviewsDescription>{review.count}&nbsp;reviews</ReviewsDescription>
    </ReviewLayout>
    <Distance>{distance}&nbsp;mi</Distance>
    <ShortDescription>{shortDescription}</ShortDescription>
  </StyledPlaceOverview>
);

export default PlaceOverview;
