import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import PlaceOverview from "../helper/PlaceOverview";
import Hammer from "hammerjs";

const Cover = styled.img`
  width: auto;
  height: 90px;
`;
const BasicInformation = styled.div`
  padding-left: 10px;
  width: 100%;
  background-color: white;
`;

const BasicPlacePreview = forwardRef(({ className, place }, ref) => {
  const { cover, name, distance, review, shortDescription } = place;
  const [isFavorite, setIsFavorite] = useState(false);

  const handleLikeChange = () => {
    const newFavorite = !isFavorite;
    setIsFavorite(newFavorite);
  };
  return (
    <div className={className}>
      <Cover src={cover} alt="" />
      <BasicInformation>
        <PlaceOverview
          name={name}
          distance={distance}
          review={review}
          toggleLike={handleLikeChange}
          isFavorite={isFavorite}
          shortDescription={shortDescription}
          placeId={place._id.$oid}
        />
      </BasicInformation>
    </div>
  );
});

/*
const PlacePreview = forwardRef(
  ({ history, selectedPlace, isPlaceViewOpen }, ref) => (
    <PlacePreviewLayout
      ref={ref}
      onClick={() => {
        history.push({ pathname: "/main/places/place", state: selectedPlace });
      }}
      place={selectedPlace}
      isOpen={isPlaceViewOpen}
    />
  )
);
*/

class PlacePreview extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.ref) {
      const swipablePreviewer = new Hammer(this.ref.current);
      swipablePreviewer.get("swipe").set({ direction: Hammer.DIRECTION_ALL });
      swipablePreviewer.on("swipeleft", this.props.handlePlacePreview);
      swipablePreviewer.on("swiperight", this.props.handlePlacePreview);
      swipablePreviewer.on("swipedown", this.props.handlePlacePreview);
      swipablePreviewer.on("swipeup", this.props.handlePlacePreview);

      swipablePreviewer.on("tap", () => {
        this.props.history.push({
          pathname: "/main/place",
          state: this.props.selectedPlace
        });
      });
    }
  }
  render() {
    const { selectedPlace, history, className } = this.props;
    return (
      <div ref={this.ref}>
        <BasicPlacePreview
          className={className}
          onClick={() => {
            history.push({
              pathname: "/main/place",
              state: selectedPlace
            });
          }}
          place={selectedPlace}
        />
      </div>
    );
  }
}

export default PlacePreview;
