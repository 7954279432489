import React from "react";
import Theme from "./styling/Theme";
import { StyledPath } from "./styling/ColorToggles";

const DakelIcon = props => {
  const { isactive = true } = props;
  return (
    <svg data-name="Layer 1" viewBox="0 0 1212.27 730.82" {...props}>
      <defs>
        <linearGradient
          id="dakel-first-gradiant"
          x1={241.49}
          y1={709.16}
          x2={241.49}
          y2={383.39}
          gradientTransform="translate(-46.47 -364.39)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f92b7f" />
          <stop offset={0.06} stopColor="#f93377" />
          <stop offset={0.33} stopColor="#f75753" />
          <stop offset={0.59} stopColor="#f67039" />
          <stop offset={0.82} stopColor="#f58029" />
          <stop offset={1} stopColor="#f58524" />
          <stop offset={1} stopColor="#ed693c" />
          <stop offset={1} stopColor="#ed6d3c" />
          <stop offset={1} stopColor="#ef773d" />
          <stop offset={1} stopColor="#f1893f" />
          <stop offset={1} stopColor="#f28f3f" />
          <stop offset={1} stopColor="#fdc60b" />
        </linearGradient>
        <linearGradient
          id="dakel-second-gradiant"
          x1={738.61}
          y1={1114.21}
          x2={738.61}
          y2={433.12}
          xlinkHref="#dakel-first-gradiant"
        />
      </defs>
      <title>{"Dakel_only_gradient"}</title>
      <StyledPath
        className="dakel-tail"
        isactive={isactive}
        fillGradient="url('#dakel-first-gradiant')"
        d="M198.26 344.77A133.23 133.23 0 01241 323.69L214.66 19c-48.47 58.56-65.59 115.68-65.59 167.93 0 61.44 23.6 116.4 49 157.66z"
        transform="translate(-86 -19)"
      />
      <StyledPath
        className="dakel-body"
        isactive={isactive}
        fillGradient="url('#dakel-second-gradiant')"
        d="M1276.47 539.55a74.39 74.39 0 00-22-11.89 85.3 85.3 0 00-36.94-2.34 75.77 75.77 0 00-27.39 10.45L1112 474.14a535.32 535.32 0 00-16-76.94l-.36-2.34.36-2.7 57.3-179.46a8.53 8.53 0 017.21-5.95c61.26-7.93 92.07-36.4 109-66.31 15.86-28.11 18.56-57.84 18.74-71.71h-36.65V99c0 4.68-17.3 4.68-17.3 0V68.91L997.91 70 984 86.57l-15.68 19.28-97.08 121.44a25.77 25.77 0 00-3.6 13.15 26.62 26.62 0 0021.26 26.13l120.9 25.77a29.33 29.33 0 005.59.54 26.72 26.72 0 0026.67-26.67V112.15a8.65 8.65 0 1117.3 0V266a43.91 43.91 0 01-44 44h-.18a49.26 49.26 0 01-9-.9L893.4 284.95l-14.77 44.69a8.49 8.49 0 01-8.11 5.95h-593a116.27 116.27 0 00-116.2 116.21v130.09a8.88 8.88 0 01-4.68 7.75h-.18l-.54.18-2.16 1.26c-1.8 1.08-4.68 2.7-7.93 5a152.6 152.6 0 00-24.5 21.26A129.56 129.56 0 0086 707.47a177.56 177.56 0 005.77 42.34h109a33.3 33.3 0 003.42-4.86 35.82 35.82 0 005.59-17.66 27.25 27.25 0 00-3.78-13.51c-2.88-4.86-7.75-10.27-16.22-15.86a8.8 8.8 0 01-3.78-7.21l.72-3.6a181.63 181.63 0 0124-36.4c15.68-18.38 39.46-38.38 71.53-41.44h2l451.9 65.95h136v-15.67a8.65 8.65 0 0117.3 0v90.09h138.2a160.29 160.29 0 00-3.42-20.72c-3.78-16.4-11.71-35.5-25.95-47.57-9.55-7.93-21.8-13.33-39.46-13.33a8.65 8.65 0 01-8.65-8.65v-56.58a8.65 8.65 0 0117.3 0v49.37c17.12 1.62 31.35 7.57 42 16.76a84.39 84.39 0 017 6.85h4c.54-.18 1.62-.36 2.88-.72a106.2 106.2 0 0010.63-3.78 118.68 118.68 0 0032.79-21.08c19.28-17.48 38.92-45.95 45.77-93.51l32.07 25.23-.18.18 45 56.94 108.83-72.07a75.48 75.48 0 00-21.79-27.41zM1113.94 86.39a12.8 12.8 0 010 25.59 12.8 12.8 0 11-1.08-25.59z"
        transform="translate(-86 -19)"
      />
    </svg>
  );
};

export default DakelIcon;
