import styled from "styled-components";
import Header from "../header/Header";
import Footer from "./footer/Footer";
import Div100vh from "react-div-100vh";

export const StyledHeader = styled(Header)`
  height: auto;
  width: 100vw;
`;
export const MainContainer = styled(Div100vh)`
  width: 100vw;
  display: grid;
  grid-template-rows: 0fr auto 0fr;
`;
export const MainContent = styled.div`
  width: 100vw;
  display: flex;
  position: relative;
  overflow: auto;
  flex-direction: column;
`;

export const StyledFotter = styled(Footer)`
  width: 100vw;
  align-self: end;
`;

export const SearchOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  height: 100%;
  width: 100vw;
  display: ${props => (props.isOverlayHidden === true ? "block" : "none")};

  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 0.5) 40%,
    rgba(0, 0, 0, 0) 80%
  );
`;
