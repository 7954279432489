import {
  PLACES_HAS_ERRORED,
  PLACES_IS_LOADING,
  PLACES_FETCH_DATA_SUCCESS,
  FAVORITES_ADD_SUCCESS,
  FAVORITES_REMOVE_SUCCESS,
  FAVORITES_FETCH_SUCCESS,
  FAVORITES_IS_LOADING
} from "../constants/action-types";
import axios from "axios";
import { SERVICES_URL } from "../../env";

export function placesHasErrored(bool) {
  return {
    type: PLACES_HAS_ERRORED,
    hasErrored: bool
  };
}
export function placesIsLoading(bool) {
  return {
    type: PLACES_IS_LOADING,
    placesIsLoading: bool
  };
}
export function favoritesIsLoading(bool) {
  return {
    type: FAVORITES_IS_LOADING,
    favoritesIsLoading: bool
  };
}
export function placesFetchDataSuccess(places) {
  return {
    type: PLACES_FETCH_DATA_SUCCESS,
    places: places
  };
}

export function favoritesFetchSuccess(favorites) {
  return {
    type: FAVORITES_FETCH_SUCCESS,
    favorites
  };
}

export function placesAddFavoritesSuccess(newFavorites) {
  return {
    type: FAVORITES_ADD_SUCCESS,
    newFavorites
  };
}

export function placesRemoveFavoritesSuccess(deletedFavorites) {
  return {
    type: FAVORITES_REMOVE_SUCCESS,
    deletedFavorites
  };
}

export function favoritePlacesFetch(userId) {
  return dispatch => {
    dispatch(favoritesIsLoading(true));
    axios
      .get(SERVICES_URL.concat(`/api/user/${userId}/favorite-places`))
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        return response.data;
      })
      .then(items => {
        dispatch(favoritesFetchSuccess(items));
        dispatch(favoritesIsLoading(false));
      })
      .catch(() => dispatch(placesHasErrored(true)));
  };
}

export function favoritePlacesRemove(userId, favorites) {
  return dispatch => {
    dispatch(favoritesIsLoading(true));
    axios
      .delete(SERVICES_URL.concat(`/api/user/${userId}/favorite-places`), {
        data: { places: favorites }
      })
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        return response.data;
      })
      .then(removedFavorites => {
        dispatch(placesRemoveFavoritesSuccess(removedFavorites));
        dispatch(favoritesIsLoading(false));
      })
      .catch(() => dispatch(placesHasErrored(true)));
  };
}

export function favoritePlacesAdd(userId, newFavorites) {
  return dispatch => {
    dispatch(favoritesIsLoading(true));
    axios
      .patch(SERVICES_URL.concat(`/api/user/${userId}/favorite-places`), {
        places: newFavorites
      })
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        return response.data;
      })
      .then(addedFavorites => {
        dispatch(placesAddFavoritesSuccess(addedFavorites));
        dispatch(favoritesIsLoading(false));
      })
      .catch(() => dispatch(placesHasErrored(true)));
  };
}

export function placesFetchData() {
  return dispatch => {
    dispatch(placesIsLoading(true));
    axios
      .get(SERVICES_URL.concat("/api/places"))
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText);
        }
        return response.data;
      })
      .then(places => {
        dispatch(placesFetchDataSuccess(places));
        dispatch(placesIsLoading(false));
      })
      .catch(() => dispatch(placesHasErrored(true)));
  };
}
