import React from "react";
import PlacePreview from "./PlacePreview";

const PlacesPreview = ({ places }) => (
  <React.Fragment>
    {places.length === 0 ? (
      <div
        style={{ marginTop: "10%", marginLeft: "auto", marginRight: "auto" }}
      >
        You have no favorites, please add some
      </div>
    ) : (
      places.map(place => <PlacePreview place={place} key={place._id} />)
    )}
  </React.Fragment>
);

export default PlacesPreview;
