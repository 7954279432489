import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skip from "../helpers/skip/Skip";
import LoginLogo from "./LoginLogo";
import { Form, Button } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import "./login.css";
import { withRouter, Redirect } from "react-router-dom";
import Div100vh from "react-div-100vh";

import { connect } from "react-redux";
import { getUser, loginUser } from "../../redux/actions/users";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const LoginContainer = styled(Div100vh)`
  display: grid;
  width: auto;
  padding-left: 5vw;
  padding-right: 5vw;
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
  justify-items: center;
`;

const StyledSkip = styled(Skip)`
  grid-column-start: 3;
  grid-row-start: 1;
  /*hiding for mvp everyone should be logged in*/
  display: none;
`;

const ForgotPassword = styled(Form.Text)`
  float: right;
  display: none;
`;

const LoginButton = styled(Button)`
  width: 100%;
  text-align: left;
  display: block;
  background: rgb(237, 137, 62);
  border-color: white;
  background: linear-gradient(
    90deg,
    rgba(237, 137, 62, 1) 0%,
    rgba(230, 95, 92, 1) 51%,
    rgba(230, 68, 128, 1) 100%
  );
`;

const LoginWithFacebook = styled(FacebookLogin)`
  width: 100%;
  text-align: left;
  display: block;
`;

const BusinessLogin = styled(Button)`
  grid-column-start: 3;
  grid-row-start: 1;
  height: 8vh;
  float: right;
  border-radius: 1.25rem 0 0 0;
  border-color: white;
  background: rgb(237, 137, 62);
  background: linear-gradient(
    145deg,
    rgba(237, 137, 62, 1) 0%,
    rgba(230, 95, 92, 1) 37%,
    rgba(230, 68, 128, 1) 100%
  );
`;

const RegiasterText = styled.div`
  text-align: center;
  padding-top: 2vh;
  color: rgb(194 194 194);
`;

const Register = styled.a`
  color: rgb(205 110 70);
`;

const BusinesLoginContainer = styled.div`
  margin-left: -5vw;
  margin-right: -5vw;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-self: self-end;
`;

const Label = styled(Form.Label)`
  @media (max-height: 515px) {
    display: none;
  }
`;

const FeedbackError = styled.span`
  display: ${props => (props.isShown ? "block" : "none")};
  color: red;
`;

const mapStateToProps = state => ({ props: state });
const mapDispatchToProps = { getUser, loginUser };

const Login = ({ props, history, getUser, loginUser }) => {
  const { hasErrored, isLoading, user } = props.userState;
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const getRecaptchaToken = () => {
    executeRecaptcha("LOGIN").then(token => {
      setRecaptchaToken(token);
    });
  };

  useEffect(() => {
    getRecaptchaToken();
  }, [hasErrored]);

  const submitHandler = async event => {
    event.preventDefault();
    const userLoginRequest = {
      captchaToken: recaptchaToken,
      userName: event.target.email.value,
      password: event.target.password.value
    };
    await loginUser(userLoginRequest);
  };

  return (
    <LoginContainer onSubmit={submitHandler}>
      {user && <Redirect to="/main/featured" />}
      <HeaderContainer>
        <StyledSkip />
        <LoginLogo />
      </HeaderContainer>
      <Form>
        <Form.Group controlId="email">
          <Label className="text-muted">Email address</Label>
          <Form.Control type="email" placeholder="Enter email" required />
        </Form.Group>
        <Form.Group controlId="password">
          <Label className="text-muted">Password</Label>
          <Form.Control type="password" placeholder="Password" required />
          <ForgotPassword className="text-muted">
            Forgot password?
          </ForgotPassword>
        </Form.Group>
        <FeedbackError isShown={hasErrored}>
          Username or password are incorrect!
        </FeedbackError>

        <LoginButton
          variant="primary"
          type="submit"
          disabled={recaptchaToken ? false : true}
        >
          Login
          <span />
        </LoginButton>
        <br />
        <LoginWithFacebook
          appId="771042936708099"
          autoLoad={false}
          fields="name,email,picture"
          callback={async response => {
            await getUser(response);
            user && history.push("/main/featured");
          }}
          cssClass="facebook-login-button"
          icon="fa-facebook"
          isMobile={false}
        />
      </Form>
      <RegiasterText>
        You don't have any accounts?
        <Register
          disabled={true}
          href=""
          onClick={() => history.push("/register")}
        >
          Register
        </Register>
      </RegiasterText>
      <BusinesLoginContainer>
        <BusinessLogin>For Business</BusinessLogin>
      </BusinesLoginContainer>
    </LoginContainer>
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
