import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const StyledPlaceActionButton = styled(Button)`
  margin-top: 10px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
  background: rgb(195, 195, 195);
  border-color: white;
`;

const actionText = {
  HOTEL: "Book A Room",
  "CAFE_PUB-RESTAURANT": "Book A Table",
  VET: "Get in Touch",
  PLACE: "Book A Table",
  EVENT: "Find Out More"
};

const PlaceActionButton = withRouter(({ history, category }) => (
  <StyledPlaceActionButton
    variant="primary"
    type="submit"
    onClick={() => alert("Comming soon!")}
  >
    {actionText[category]}
  </StyledPlaceActionButton>
));

export default PlaceActionButton;
