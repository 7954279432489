import React from "react";
import DakelIcon from "../../../images/svgs/DakelIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const PlaceContainer = styled.div`
  position: relative;
  width: 50px;
  z-index: 0;

  ${({ active }) =>
    active &&
    `
    path {
      fill: rgb(229, 74, 120);
    }
  `}
`;

const Title = styled.label`
  position: absolute;
  width: auto;
  height: auto;
  z-index: 100;
  top: 20%;
  white-space: nowrap;
  color: rgb(227, 129, 76);
`;

const ShortDescription = styled.label`
  position: absolute;
  color: rgb(210, 98, 121);
  z-index: 100;
  top: 38%;
  width: 80px;
  display: ${({ isVisible }) => (isVisible ? "initial" : "none")};
`;

const DogContainer = styled.div`
  position: absolute;
  top: 20%;
  right: 10px;
  width: 30px;
  height: 30px;
  background: white;
  z-index: 1;

  ${({ active }) =>
    active &&
    `
    background: rgb(229, 74, 120);
    path {
      fill: white;
    }
  `}
`;
const Dog = styled(DakelIcon)`
  width: 30px;
  height: 30px;
  z-index: 3;
`;

const PlaceMarker = ({
  toggle,
  isActive,
  placeId,
  shortDescription,
  name,
  zoomLevel
}) => (
  <PlaceContainer active={isActive} onClick={() => toggle(placeId)}>
    <FontAwesomeIcon
      size="6x"
      icon="map-marker-alt"
      style={{ color: "white", paddingTop: "5px" }}
    />
    <DogContainer active={isActive}>
      <Dog />
    </DogContainer>
    <Title>{name}</Title>
    <ShortDescription isVisible={zoomLevel >= 16 ? true : false}>
      {shortDescription}
    </ShortDescription>
  </PlaceContainer>
);

export default PlaceMarker;
