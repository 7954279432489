import React from "react";
import styled from "styled-components";
import DogLogo from "../../images/dogs_allowed_logo.png";

import DogsAllowedText from "../../images/da_value_prop.png";

const StyledDogLogo = styled.img`
  grid-column-start: 3;
  grid-column-end: 6;
  grid-row-start: 1;
  align-self: end;
  text-align: center;
  max-width: 100%;
  height: auto;
  @media (max-height: 450px) {
    display: none;
  }
`;

const StyledDogsAllowedText = styled.img`
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 2;
  align-self: baseline;
  text-align: center;
  max-width: 100%;
  height: auto;
`;

const LoginLogo = () => {
  return (
    <React.Fragment>
      <StyledDogLogo src={DogLogo} alt={"dogs-logo"} />
      <StyledDogsAllowedText src={DogsAllowedText} alt={"dogs-allowed-text"} />
    </React.Fragment>
  );
};

export default LoginLogo;
