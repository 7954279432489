import React from "react";
import {
  StyledRect,
  StyledEllipse,
  StyledCircle
} from "./styling/ColorToggles";

function MapIcon(props) {
  const { isactive = true } = props;
  return (
    <svg data-name="Layer 1" viewBox="0 0 226.05 203" {...props}>
      <defs>
        <linearGradient
          id="map-first-gradient"
          x1={94.46}
          y1={206.78}
          x2={131.59}
          y2={-3.78}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f92b7f" />
          <stop offset={0.06} stopColor="#f93377" />
          <stop offset={0.33} stopColor="#f75753" />
          <stop offset={0.82} stopColor="#f58029" />
          <stop offset={1} stopColor="#f58524" />
          <stop offset={1} stopColor="#ed693c" />
          <stop offset={1} stopColor="#ed6d3c" />
          <stop offset={1} stopColor="#ef773d" />
          <stop offset={1} stopColor="#f1893f" />
          <stop offset={1} stopColor="#f28f3f" />
          <stop offset={1} stopColor="#fdc60b" />
        </linearGradient>
        <linearGradient
          id="map-second-gradient"
          x1={111.16}
          y1={86.78}
          x2={116.35}
          y2={57.3}
          xlinkHref="#map-first-gradient"
        />
        <linearGradient
          id="map-third-gradient"
          x1={110.37}
          y1={91.74}
          x2={116.45}
          y2={57.26}
          xlinkHref="#map-first-gradient"
        />
      </defs>
      <title>{"Map_gradient"}</title>
      <StyledRect
        isactive={isactive}
        className="prefix__map-1"
        fill="url(#map-first-gradient)"
        width={226.05}
        height={203}
        rx={80}
        ry={80}
      />
      <StyledEllipse
        isactive={isactive}
        className="prefix__map-2"
        fill="url(#map-second-gradient)"
        cx={113.75}
        cy={72.04}
        rx={15.15}
        ry={14.96}
      />
      <path
        className="prefix__map-3"
        fill="#fff"
        d="M165.91 83.55c0 35.18-35 71.43-47.84 83.54a6.87 6.87 0 01-9.39.1c-12.91-11.7-47.77-46.7-47.77-83.64 0-31.23 24-56.55 52.52-56.55s52.48 25.32 52.48 56.55z"
      />
      <path
        className="prefix__map-4"
        fill="#fff"
        d="M121.44 163.46l-8.03 8.52-8-8.48 8.03-8.52 8 8.48z"
      />
      <StyledCircle
        isactive={isactive}
        className="prefix__map-5"
        fill="url(#map-third-gradient)"
        cx={113.41}
        cy={74.5}
        r={17.5}
      />
    </svg>
  );
}

export default MapIcon;
