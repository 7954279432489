import React, { useState } from "react";
import { connect } from "react-redux";
import {
  placesFetchData,
  favoritePlacesFetch
} from "../../redux/actions/places";
import {
  StyledHeader,
  MainContainer,
  MainContent,
  StyledFotter,
  SearchOverlay
} from "./MainStyles";
import Featured from "./places/Featured";
import Place from "./places/Place";
import PlacesPreview from "./places/placespreview/PlacesPreview";
import Map from "./map/Map";
import Settings from "./settings/Settings";

import Theme from "../../Theme";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Fuse from "fuse.js";
import { FAVORITES, FEATURED, MAP, SETTINGS } from "./Paths";

const keys = {
  NAME: "name",
  OPERATION_HOURS: "operationHours",
  //CATEGORY: "category",
  KEYWORDS: "keywords"
};
const { NAME, KEYWORDS } = keys;

const fuseOptions = {
  shouldSort: true,
  tokenize: true,
  threshold: 0.2,
  location: 0,
  distance: 0,
  maxPatternLength: 12,
  minMatchCharLength: 3,
  keys: [NAME, KEYWORDS]
};

const footerIndexes = {
  "/main/featured": 0,
  "/main/favorites": 1,
  "/main/map": 2,
  "/main/settings": 3
};

const mapStateToProps = state => ({
  props: {
    ...state.placesState,
    userId: state.userState.user !== null ? state.userState.user._id.$oid : null
  }
});
const mapDispatchToProps = {
  placesFetchData,
  favoritePlacesFetch
};

const Main = ({ props, history, placesFetchData, favoritePlacesFetch }) => {
  const {
    placesIsLoading,
    favoritesIsLoading,
    placesHasErrored,
    places,
    placesLoaded,
    favorites,
    favoritesLoaded,
    userId
  } = props;

  const searchIconHandler = inputValue => {
    setQuery(inputValue);
  };

  const setSearchFocusHandler = () => {
    setIsSearchFocusd(true);
  };
  const unsetSearchFocusHandler = () => {
    setIsSearchFocusd(false);
  };

  const onChangeHandler = e => {
    const { target = {} } = e;
    const { value = "" } = target;
    setQuery(value);
    setIsSearchFocusd(false);
  };

  const [isSearchFocusd, setIsSearchFocusd] = useState(false);
  const [query, setQuery] = useState("");

  // pull data if it is not pulled
  !placesLoaded && !placesIsLoading && placesFetchData();

  !favoritesLoaded &&
    userId !== null &&
    !favoritesIsLoading &&
    favoritePlacesFetch(userId);

  const searchPlaces =
    history.location.pathname === FAVORITES ? favorites : places;
  const fuse = new Fuse(searchPlaces, fuseOptions);

  const queryFilteredPlaces = query ? fuse.search(query) : searchPlaces;

  return (
    <Theme>
      <MainContainer>
        {userId == null && <Redirect to="/login" />}
        <StyledHeader
          handleIconSearch={searchIconHandler}
          searchText={query}
          handleSearchFocus={setSearchFocusHandler}
          handleSearchUnsetFocus={unsetSearchFocusHandler}
          handleOnChange={onChangeHandler}
        />
        <MainContent>
          <Switch>
            <Route
              exact
              path="/main/featured"
              render={() => (
                <Featured places={queryFilteredPlaces} searchText={query} />
              )}
            />
            <Route exact path="/main/place" render={() => <Place />} />
            <Route
              exact
              path="/main/favorites"
              render={() => (
                <PlacesPreview places={/*favorites*/ queryFilteredPlaces} />
              )}
            />
            <Route
              exact
              path="/main/map"
              render={() => <Map places={/*places*/ queryFilteredPlaces} />}
            />
            <Route exact path="/main/settings" render={() => <Settings />} />
            <Redirect to="/login" />}
          </Switch>
          <SearchOverlay isOverlayHidden={isSearchFocusd} />
        </MainContent>
        <StyledFotter
          activeFooterIndex={footerIndexes[history.location.pathname]}
        />
      </MainContainer>
    </Theme>
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Main)
);
