import styled from "styled-components";

export const StyledRect = styled.rect`
  fill: ${props => (props.isactive ? props.fill : props.theme.colors.inactive)};
`;

export const StyledEllipse = styled.ellipse`
  fill: ${props => (props.isactive ? props.fill : props.theme.colors.inactive)};
`;

export const StyledCircle = styled.circle`
  fill: ${props => (props.isactive ? props.fill : props.theme.colors.inactive)};
`;

export const StyledPath = styled.path`
  fill: ${props =>
    props.isactive ? props.fillGradient : props.theme.colors.inactive};
`;
