import React from "react";
import Theme from "./styling/Theme";
import { StyledPath } from "./styling/ColorToggles";

function HeartIcon(props) {
  const { isactive = true } = props;
  return (
    <svg data-name="Layer 1" viewBox="0 0 235.68 203" {...props}>
      <defs>
        <linearGradient
          id="heart-gradient"
          x1={158.86}
          y1={246.76}
          x2={195.43}
          y2={39.33}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f92b7f" />
          <stop offset={0.06} stopColor="#f93377" />
          <stop offset={0.53} stopColor="#f75753" />
          <stop offset={0.82} stopColor="#f58029" />
          <stop offset={1} stopColor="#f58524" />
          <stop offset={1} stopColor="#ed693c" />
          <stop offset={1} stopColor="#ed6d3c" />
          <stop offset={1} stopColor="#ef773d" />
          <stop offset={1} stopColor="#f1893f" />
          <stop offset={1} stopColor="#f28f3f" />
          <stop offset={1} stopColor="#fdc60b" />
        </linearGradient>
      </defs>
      <title>{"Heart icon_gradient"}</title>
      <StyledPath
        isactive={isactive}
        fill="url(#heart-gradient)"
        d="M300.87 109.68A61.66 61.66 0 00183 84.25a61.63 61.63 0 10-96 72.45l94.06 93.43a2.94 2.94 0 002.09.87 3 3 0 002.11-.87l97.55-96.92a61.37 61.37 0 0018-43.52z"
        transform="translate(-65.19 -48)"
      />
    </svg>
  );
}

export default HeartIcon;
