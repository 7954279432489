import React from "react";
import styled from "styled-components";
import { ReactComponent as Bone10 } from "../../images/bone/bone-10.svg";
import { ReactComponent as Bone20 } from "../../images/bone/bone-20.svg";
import { ReactComponent as Bone30 } from "../../images/bone/bone-30.svg";
import { ReactComponent as Bone40 } from "../../images/bone/bone-40.svg";
import { ReactComponent as Bone50 } from "../../images/bone/bone-50.svg";
import { ReactComponent as Bone60 } from "../../images/bone/bone-60.svg";
import { ReactComponent as Bone70 } from "../../images/bone/bone-70.svg";
import { ReactComponent as Bone80 } from "../../images/bone/bone-80.svg";
import { ReactComponent as Bone90 } from "../../images/bone/bone-90.svg";
import { ReactComponent as Bone100 } from "../../images/bone/bone-100.svg";

const EmptyBone = styled(Bone10)`
  path {
    fill: white;
  }
  width: 14px;
  height: 14px;
`;

const fill = [
  <Bone100 width="14px" height="14px" />,
  <Bone10 width="14px" height="14px" />,
  <Bone20 width="14px" height="14px" />,
  <Bone30 width="14px" height="14px" />,
  <Bone40 width="14px" height="14px" />,
  <Bone50 width="14px" height="14px" />,
  <Bone60 width="14px" height="14px" />,
  <Bone70 width="14px" height="14px" />,
  <Bone80 width="14px" height="14px" />,
  <Bone90 width="14px" height="14px" />
];
const Reviews = ({ review, className }) => {
  const reviewIndicator = [
    <EmptyBone />,
    <EmptyBone />,
    <EmptyBone />,
    <EmptyBone />,
    <EmptyBone />
  ];

  const score = Math.trunc(review.score);
  const leftover = (review.score % 1).toFixed(4);
  const roundedLeftoverIndex = Math.round(leftover * 10);
  for (let i = 0; i < score; i++) {
    reviewIndicator[i] = fill[0];
  }

  if (score === 0 && roundedLeftoverIndex !== 0) {
    reviewIndicator[0] = fill[roundedLeftoverIndex];
  } else if (score < 5 && roundedLeftoverIndex !== 0) {
    reviewIndicator[score] = fill[roundedLeftoverIndex];
  }

  return <div className={className}>{reviewIndicator}</div>;
};

export default Reviews;
