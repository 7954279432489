import React from "react";
import { connect } from "react-redux";
import Main from "./components/main/Main";
import Login from "./components/login/Login";
import Localizator from "./components/localizator/Localizator";
import Register from "./components/registration/Register";
import GDPR from "./components/gdpr/GDPR";
import Home from "./components/home/Home";
import Div100vh from "react-div-100vh";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import styled from "styled-components";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import spinner from "./images/spinner-dogs-allowed.gif";

const mapStateToProps = state => ({
  props: {
    placesIsLoading: state.placesState.placesIsLoading,
    favoritesIsLoading: state.placesState.favoritesIsLoading,
    placesHasErrored: state.placesState.placesHasErrored,
    userIsLoading: state.userState.isLoading,
    userHasErrored: state.userState.hasErrored
  }
});

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: ${({ isLoading }) => (isLoading ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
  opacity: 0.8;
  background: white;
`;

const StyledApp = styled(Div100vh)`
  /* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

  @media (min-width: 1281px) {
    ::before {
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      font-size: 3em;
      content: "We don't support desktops, please use a mobile device!";
    }
  }

  /* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

  @media (min-width: 1025px) and (max-width: 1280px) {
    //CSS
    ::before {
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      font-size: 3em;
      content: "We don't support desktops and laptops, please use a mobile device!";
    }
  }

  /* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

  @media (min-width: 768px) and (max-width: 1024px) {
    //CSS
    ::before {
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      font-size: 3em;
      content: "We don't support tablets and ipads, please use a mobile device!";
    }
  }

  /* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //CSS
    ::before {
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      font-size: 3em;
      content: "We don't support tablets and ipads, please use a mobile device!";
    }
  }

  /* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

  @media (min-width: 481px) and (max-width: 767px) {
    //CSS
    ::before {
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      font-size: 3em;
      content: "We don't support small tablets and landskape mode, please use a mobile device!";
    }
  }

  /* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

  @media (min-width: 320px) and (max-width: 480px) {
    //CSS
  }
`;

const App = ({ props }) => {
  const {
    placesIsLoading,
    favoritesIsLoading,
    userIsLoading,
    userHasErrored
  } = props;
  let isLoading = placesIsLoading || favoritesIsLoading || userIsLoading;

  return (
    <StyledApp className="App">
      <SpinnerContainer isLoading={isLoading && !userHasErrored}>
        <img
          src={spinner}
          alt={"spinner"}
          style={{ position: "absolute", zIndex: 1001 }}
        />
      </SpinnerContainer>
      <GoogleReCaptchaProvider
        reCaptchaKey={"6Ld7nM8UAAAAAA-vvSyIGjPYdIZAlxq7XpJAj4To"}
      >
        <Router>
          <Switch>
            <Route path="/main" component={Main} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/forgot-password" />
            <Route exact path="/dogs-selection" />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/gdpr" component={GDPR} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/location-finder" component={Localizator} />
            <Redirect to="/login" />}
          </Switch>
        </Router>
      </GoogleReCaptchaProvider>
    </StyledApp>
  );
};

export default withRouter(connect(mapStateToProps)(App));
