import React, { useRef, useEffect } from "react";

import { Form, InputGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Search({
  className,
  unsetFocus,
  setFocus,
  searchText,
  handleOnChange
}) {
  const searchInput = useRef(null);
  useEffect(() => {
    searchInput.current.value = searchText;
  }, [searchInput]);

  return (
    <div className={className}>
      <Form.Row>
        <Form.Group as={Col}>
          <InputGroup>
            <Form.Control
              ref={searchInput}
              onChange={handleOnChange}
              onFocus={() => {
                setFocus();
              }}
              onBlur={() => {
                unsetFocus();
              }}
              className="form-control form-control-sm ml-3 w-75"
              type="text"
              placeholder="Search for things.."
              value={searchText}
            />
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon="search" />
              </InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </Form.Group>
      </Form.Row>
    </div>
  );
}

export default Search;
