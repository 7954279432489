export const routesDisplaySettings = {
  "/gdpr": {
    showBackButton: true,
    showAvatar: false,
    showSearch: false,
    searchIcons: false
  },
  "/privacy-policy": {
    showBackButton: true,
    showAvatar: false,
    showSearch: false,
    searchIcons: false
  },
  "/main/featured": {
    showBackButton: false,
    showAvatar: false, //true,
    showSearch: true,
    searchIcons: true
  },
  "/main/place": {
    showBackButton: true,
    showAvatar: false, //true,
    showSearch: false,
    searchIcons: false
  },
  "/main/favorites": {
    showBackButton: false,
    showAvatar: false, //true,
    showSearch: true,
    searchIcons: false
  },
  "/main/map": {
    showBackButton: false,
    showAvatar: false, //true,
    showSearch: true,
    searchIcons: false
  },
  "/main/settings": {
    showBackButton: true,
    showAvatar: false, //true,
    showSearch: true,
    searchIcons: true
  },
  "/register": {
    showBackButton: true,
    showAvatar: false,
    showSearch: false,
    searchIcons: false
  }
};
