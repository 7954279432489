import React, { useState } from "react";
import styled from "styled-components";
import PlaceLocationView from "../../placemapview/PlaceLocationView";
import { withRouter } from "react-router-dom";
import PlaceOverview from "../../helper/PlaceOverview";
import PlaceActionButton from "../../placeactionbutton/PlaceActionButton";

const PlaceCoverImageContainer = styled.div`
  width: 50%;
  height: 20vh;
  background-color: #eeeeee;
`;

const PlaceBodyContainer = styled.div`
  display: flex;
`;

const CoverImage = styled.img`
  /*max-width: 100%;*/
  /*max-height: 100%;*/
  /*TODO need to remove*/
  height: 20vh; /* Make sure images are scaled correctly. */
  width: auto; /* Adhere to container width. */
  display: block;
`;

const PlaceOverviewLayout = styled(PlaceOverview)`
  padding-left: 5vw;
`;

const PlacePreview = withRouter(({ place, history, className }) => {
  const { name, distance, review, shortDescription, cords, category } = place;

  return (
    <div className={className}>
      <PlaceOverviewLayout
        name={name}
        distance={distance}
        review={review}
        placeId={place._id.$oid}
        shortDescription={shortDescription}
      />
      <PlaceBodyContainer
        onClick={() => {
          history.push({ pathname: "/main/place", state: place });
        }}
      >
        <PlaceCoverImageContainer>
          <CoverImage alt="" src={place.coverPreview} />
        </PlaceCoverImageContainer>
        <PlaceLocationView
          cords={cords}
          name={name}
          shortDescription={shortDescription}
          size={{ height: "20vh", width: "50%" }}
        />
      </PlaceBodyContainer>
      <PlaceActionButton category={category} />
    </div>
  );
});

export default PlacePreview;
