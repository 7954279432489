import React from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import PlaceMarker from "../map/PlaceMarker";
import { MapDecorators } from "../../../sharedStyles";

const PlaceLocationView = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};

  ${MapDecorators};
`;

const PlaceMapView = ({ cords, name, shortDescription, size }) => {
  const location = {
    lat: cords.lat - 0.001,
    lng: cords.lng
  };
  return (
    <PlaceLocationView height={size.height} width={size.width}>
      <GoogleMapReact
        draggable={false}
        bootstrapURLKeys={{ key: "AIzaSyDOk68zyulvX1vsyCUl_8NE7A4_I2HMrpI" }}
        center={location}
        zoom={15}
      >
        <PlaceMarker
          lat={cords.lat}
          lng={cords.lng}
          name={name}
          shortDescription={shortDescription}
        />
      </GoogleMapReact>
    </PlaceLocationView>
  );
};

export default PlaceMapView;
