import React from "react";
import styled from "styled-components";
import Reviews from "../../../helpers/Reviews";
import Theme from "../../../../Theme";

const DescriptionLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${props => props.theme.colors.backgroundGray};
  width: 35vw;
  height: 95px;
`;
const VenueName = styled.h6`
  margin-top: 0px;
  margin-bottom: 0px;
  height: 23px;
  width: auto;
  color: ${props => props.theme.colors.blackText};
  font-family: ${props => props.theme.fonts[1]};
  font-size: ${props => props.theme.fontSizes.small};
  line-height: 16px;
`;
const ShortDescription = styled.div`
  height: 23px;
  width: auto;
  color: ${props => props.theme.grayLabelText};
  font-family: ${props => props.theme.fonts[1]};
  font-size: ${props => props.theme.fontSizes.smallest};
  line-height: 10px;
`;

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: start;
  margin-top: 5px;
  font-size: ${props => props.theme.fontSizes.small};
`;
const Distance = styled.span`
  height: 10px;
  width: 40px;
  color: ${props => props.theme.colors.pinkLabel};
  font-family: ${props => props.theme.fonts[1]};
  font-size: ${props => props.theme.fontSizes.smallest};
  line-height: 10px;
`;
const OperationHours = styled.span`
  height: 10px;
  width: 40px;
  color: ${props => props.theme.colors.pinkLabel};
  font-family: ${props => props.theme.fonts[1]};
  font-size: ${props => props.theme.fontSizes.smallest};
  line-height: 10px;
  justify-self: end;
`;

const Description = ({ place }) => (
  <Theme>
    <DescriptionLayout>
      <VenueName>{place.name}</VenueName>
      <Reviews review={place.review} />
      <ShortDescription>{place.shortDescription}</ShortDescription>
      <Info>
        <Distance>{place.distance}&nbsp;mi</Distance>
        <OperationHours>{place.operationHours}</OperationHours>
      </Info>
    </DescriptionLayout>
  </Theme>
);

export default Description;
