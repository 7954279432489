import React, { useState } from "react";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import { withRouter } from "react-router-dom";

import "./place.css";
import PlaceOverview from "../helper/PlaceOverview";
import PlaceLocationView from "../placemapview/PlaceLocationView";
import PlaceActionButton from "../placeactionbutton/PlaceActionButton";

const PlaceContainer = styled.div`
  margin: 1em;
  .image-gallery-image img {
    max-height: auto;
    max-width: 600px;
  }
  .image-gallery-left-nav:focus,
  .image-gallery-right-nav:focus {
    outline: none;
  }
`;

const GalleryContainer = styled.div`
  display: flex;
  justify-content: center;
  /*
  .image-gallery {
    flex-grow: 1;
  }
  */
`;

const Description = styled.p`
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Address = styled.div`
  font-size: 12px;
  background-color: rgb(238, 238, 238);
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  width: 100%;
`;
const Place = withRouter(({ history }) => {
  const {
    name,
    images,
    review,
    description,
    distance,
    shortDescription,
    cords,
    address,
    category,
    _id
  } = history.location.state;

  return (
    <PlaceContainer>
      <PlaceOverview
        name={name}
        distance={distance}
        review={review}
        placeId={_id.$oid}
        shortDescription={shortDescription}
      />
      <GalleryContainer>
        <ImageGallery
          items={images}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={true}
        />
      </GalleryContainer>
      <Description>{description}</Description>
      <PlaceLocationView
        cords={cords}
        name={name}
        shortDescription={shortDescription}
        size={{ height: "40vh", width: "100%" }}
      />
      <Address>{address.street + ", " + address.city}</Address>
      <PlaceActionButton category={category} />
    </PlaceContainer>
  );
});

export default Place;
