import React, { useState } from "react";
import styled from "styled-components";
import Search from "../helpers/search/Search";
import Avatar from "./Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import Theme from "../../Theme";

import { ReactComponent as DogsAllowedText } from "../../images/DogsAllowed.svg";
import Button from "react-bootstrap/Button";

import Events from "../../images/search/events_button.png";
import Restaurants from "../../images/search/restaurants_button.png";
import Accommodations from "../../images/search/accommodations_button.png";
import Vets from "../../images/search/vets_button.png";

import { sharedButtonStyles } from "../../sharedStyles";
import { routesDisplaySettings } from "./RouteDisplaySettings";

const StyledHeader = styled.header`
  background: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 6vh auto;
  grid-column-gap: 5px;
  justify-items: center;
  align-items: center;
  justify-items: center;

  .input-group-text {
    background: white;
    border: none;
    border-bottom: 1px solid rgb(240, 240, 240);
    box-shadow: 0px 1px rgb(240, 240, 240);
  }

  .form-control:focus ~ .input-group-prepend .input-group-text {
    border-bottom: 1px solid #80bdff;
  }
`;

const LineSpacing = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid rgb(245, 246, 248);
  align-self: end;
`;

const StyledSearch = styled(Search)`
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  width: 80%;
  display: ${props => (props.isVisible ? "block" : "none")};
`;

const StyledAvatar = styled(Avatar)`
  height: "4vh";
  grid-column-start: 1;
  grid-row-start: 1;
  height: 4vh;
  width: auto;
  border-radius: 50%;
  border-style: 1px solid black;
  display: ${props => (props.isVisible ? "block" : "none")};
`;

const BackButton = styled.div`
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: start;
  padding-left: 10px;
  display: ${props => (props.isVisible ? "block" : "none")};
`;

const SearchIcons = styled.div`
  width: 100%;
  height: auto;
  display: ${props => (props.isVisible ? "flex" : "none")};
  justify-content: space-around;
  text-align: center;
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 4;
  ${sharedButtonStyles}
`;

const StyledDogsAllowedText = styled(DogsAllowedText)`
  height: 70px;
  width: 200px;
  grid-column-start: 2;
  grid-row-start: 1;
`;

const SearchIconContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const SearchIconLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.small};
  width: 100%;
  text-align: center;
  color: black;
`;

const SearchButton = styled(Button)`
  outline: none;
  border: none;
  background-color: white;
  padding: 0px;
`;

const Header = withRouter(
  ({
    history,
    className,
    handleSearchUnsetFocus,
    handleSearchFocus,
    handleIconSearch,
    searchText,
    handleOnChange
  }) => {
    const { pathname } = history.location;

    const {
      showBackButton,
      showAvatar,
      showSearch,
      searchIcons
    } = routesDisplaySettings[pathname];

    return (
      <Theme>
        <StyledHeader className={className}>
          <BackButton
            className="backButton"
            isVisible={showBackButton || searchText !== ""}
            onClick={
              searchText === "" ? history.goBack : () => handleIconSearch("")
            }
          >
            <FontAwesomeIcon size="1x" icon="arrow-left" />
          </BackButton>
          <StyledAvatar isVisible={showAvatar} />
          <LineSpacing />
          <StyledDogsAllowedText />
          {handleSearchUnsetFocus && handleSearchFocus && (
            <React.Fragment>
              <StyledSearch
                handleOnChange={handleOnChange}
                searchText={searchText}
                isVisible={showSearch}
                unsetFocus={handleSearchUnsetFocus}
                setFocus={handleSearchFocus}
              />
              <SearchIcons isVisible={searchIcons}>
                <SearchButton
                  onClick={() => handleIconSearch("Food and Drinks")}
                >
                  <SearchIconContainer>
                    <img src={Restaurants} alt={"restaurants"} height="50px" />
                  </SearchIconContainer>
                  <SearchIconLabel>Food</SearchIconLabel>
                </SearchButton>
                <SearchButton onClick={() => handleIconSearch("Accomodations")}>
                  <SearchIconContainer>
                    <img
                      src={Accommodations}
                      alt={"accommodations"}
                      height="50px"
                    />
                  </SearchIconContainer>
                  <SearchIconLabel>Hotels</SearchIconLabel>
                </SearchButton>
                <SearchButton onClick={() => handleIconSearch("Events")}>
                  <SearchIconContainer>
                    <img src={Events} alt={"events"} height="50px" />
                  </SearchIconContainer>
                  <SearchIconLabel>Events</SearchIconLabel>
                </SearchButton>
                <SearchButton onClick={() => handleIconSearch("Vets")}>
                  <SearchIconContainer>
                    <img src={Vets} alt={"vets"} height="50px" />
                  </SearchIconContainer>
                  <SearchIconLabel>Vets</SearchIconLabel>
                </SearchButton>
              </SearchIcons>
            </React.Fragment>
          )}
        </StyledHeader>
      </Theme>
    );
  }
);
export default Header;
