import React, { Component } from "react";
import styled from "styled-components";
import Loading from "../../helpers/Loading";

import {
  PlacesNav,
  FavoritesNav,
  LocationNav
  // SettingsNav
} from "./FooterButtons";
import { Link } from "react-router-dom";
import { sharedButtonStyles } from "../../../sharedStyles";

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  ${sharedButtonStyles}
`;

class Footer extends Component {
  constructor(props) {
    super(props);

    this.toggleClass = this.toggleClass.bind(this);
    this.state = {
      activeFooterIndex: props.activeFooterIndex,
      center: {
        lat: 52.2053,
        lng: 0.1218
      },
      zoom: 13
    };
  }

  toggleClass(index, e) {
    this.setState({ activeFooterIndex: index });
  }

  componentDidMount() {
    this.setState({ activeFooterIndex: this.state.activeFooterIndex });
  }

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }

    return (
      <StyledFooter className={this.props.className}>
        <Link to="/main/featured">
          <PlacesNav
            isbuttonactive={this.state.activeFooterIndex === 0 ? 1 : 0}
            handleClick={this.toggleClass.bind(this, 0)}
          />
        </Link>
        <Link
          to={{
            pathname: "/main/map",
            places: this.props.places,
            center: this.state.center
          }}
        >
          <LocationNav
            isbuttonactive={this.state.activeFooterIndex === 2 ? 1 : 0}
            handleClick={this.toggleClass.bind(this, 2)}
          />
        </Link>
        <Link to="/main/favorites">
          <FavoritesNav
            isbuttonactive={this.state.activeFooterIndex === 1 ? 1 : 0}
            handleClick={this.toggleClass.bind(this, 1)}
          />
        </Link>
        {/*
        <Link to="/main/settings">
          <SettingsNav
            isbuttonactive={this.state.activeFooterIndex === 3 ? 1 : 0}
            handleClick={this.toggleClass.bind(this, 3)}
          />
        </Link>
         */}
      </StyledFooter>
    );
  }
}

export default Footer;
