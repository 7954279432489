import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    blackText: "#333333",
    grayLabelText: "#999999",
    backgroundGray: "#eeeeee",
    pinkLabel: "#f83376",
    onyx: "#36313D",
    inactive: "#c4c4c4"
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    smallest: "8px",
    small: "12px",
    medium: "14px",
    large: "3em"
  },
  containerSize: "65vh",
  headerSize: "18vh",
  footerButtonSize: "30px",
  footer: { searchIconSize: "" }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
