import {
  USER_GET_ERRORED,
  USER_GET_LOADING,
  USER_GET_SUCCESS,
  CREATE_USER_SUCCESS,
  LOGIN_USER_SUCCESS,
  GET_LOGIN_CAPCHA_SUCCESS
} from "../../constants/action-types.js";

export function reducer(
  state = {
    hasErrored: false,
    isLoading: false,
    user: null,
    capchaLoginToken: null
  },
  action
) {
  switch (action.type) {
    case USER_GET_ERRORED:
      return { ...state, hasErrored: action.hasErrored };
    case USER_GET_LOADING:
      return { ...state, isLoading: action.isLoading, hasErrored: false };
    case USER_GET_SUCCESS:
      return { ...state, user: action.user, hasErrored: false };
    case LOGIN_USER_SUCCESS:
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        hasErrored: false,
        user: { ...state.user, _id: { $oid: action.userId } }
      };
    case GET_LOGIN_CAPCHA_SUCCESS:
      return {
        ...state,
        hasErrored: false,
        capchaLoginToken: action.capchaToken
      };
    default:
      return state;
  }
}
