import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

import {
  faMapMarkerAlt,
  faCog,
  faPaw,
  faHeart,
  faSearch,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

import "normalize.css";
import "./styles.css";

import "react-image-gallery/styles/css/image-gallery.css";

import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";

import { BrowserRouter as Router, Route } from "react-router-dom";

library.add(fab, faMapMarkerAlt, faCog, faPaw, faHeart, faSearch, faArrowLeft);

const store = configureStore();
const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route path="/*" component={App} />
    </Router>
  </Provider>,
  rootElement
);
