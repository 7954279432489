import React from "react";
import styled from "styled-components";
import PlacePreview from "./featuredpreview/PlacePreview";
import PlacesPreview from "./placespreview/PlacesPreview";
import Theme from "../../../Theme";

const PlacesContainer = styled.div`
  padding-left: 2vw;
  padding-top: 2vh;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SectionTitle = styled.h6`
  padding-left: 30px;
  /*padding-bottom: 10px;*/
  margin-top: 10px;
  margin-bottom: 10px;
  height: 23px;
  color: ${props => props.theme.colors.blackText};
  font-family: ${props => props.theme.fonts[1]};
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 500;
  line-height: 19px;
`;

const PlacesLayout = styled.div`
  display: flex;
  overflow-x: auto;
  min-height: 95px;
`;

const Featured = ({ places, placesIsLoading, searchText }) => {
  return (
    <Theme>
      {searchText === null || searchText === "" ? (
        <PlacesContainer>
          <section>
            <SectionTitle>Pawsome Pubs & Eating Places</SectionTitle>
            <PlacesLayout>
              {places
                .filter(({ category }) => category === "CAFE_PUB-RESTAURANT")
                .slice(0, 4)
                .map(place => (
                  <PlacePreview place={place} key={place._id.$oid} />
                ))}
            </PlacesLayout>
          </section>
          <section>
            <SectionTitle>Featured Bow-WoW Hotels</SectionTitle>
            <PlacesLayout>
              {places
                .filter(({ category }) => category === "HOTEL")
                .slice(0, 4)
                .map(place => (
                  <PlacePreview place={place} key={place._id.$oid} />
                ))}
            </PlacesLayout>
          </section>
          <section>
            <SectionTitle>Discover Local Pooch Events</SectionTitle>
            <PlacesLayout>
              {places
                .filter(({ category }) => category === "EVENT")
                .slice(0, 4)
                .map(place => (
                  <PlacePreview place={place} key={place._id.$oid} />
                ))}
            </PlacesLayout>
          </section>
        </PlacesContainer>
      ) : (
        <PlacesPreview places={places} />
      )}
    </Theme>
  );
};

export default Featured;
